//Translate
export const TRANSLATE_END = 'translate(0,0)'
export const TRANSLATE_START = 'translate(0,100px)'
export const TRANSLATE_START_SMALL = 'translate(0,50px)'


export const TRANSFORM_BACKGROUND_LTR_START = 'skewY(-15deg) scaleY(1.25)'
export const TRANSFORM_BACKGROUND_LTR_END = 'skewY(-15deg) scaleY(0.9)'

export const TRANSFORM_BACKGROUND_LTRNS_START = 'skewY(-15deg)'
export const TRANSFORM_BACKGROUND_LTRNS_END = 'skewY(-15deg)'

export const TRANSFORM_BACKGROUND_RTL_START = 'skewY(15deg) scaleY(1.25)'
export const TRANSFORM_BACKGROUND_RTL_END = 'skewY(15deg) scaleY(0.9)'


//Time
export const TIME_DELAY = 200

export const GRADIENT_ONE = [[57,1,100], [255,238,0], [0,159,255], [229,145,0]]
export const GRADIENT_TWO = [[236,46,75], [0,93,39], [57,1,100], [246,255,0]]
export const WRAPPER_BACKGROUND_CLASS = ['Purple', 'Green', 'Blue', 'Yellow']
export const SITE_TITLE = 'Creative Equity Partners'
export const GA_CODE = 'UA-49847615-2'