
const gradients = [
      "linear-gradient(-45deg, #ec2f4b, #390164)", 
      "linear-gradient(-135deg, #009fff, #390164)"]

export default class ColorUtils {
      getColor3RGB( colorOne, colorTwo, step, boundary) {
            let r = Math.abs(Math.floor((colorTwo[0] - colorOne[0]) * step/boundary + colorOne[0]))
            let g = Math.abs(Math.floor((colorTwo[1] - colorOne[1]) * step/boundary + colorOne[1]))
            let b = Math.abs(Math.floor((colorTwo[2] - colorOne[2]) * step/boundary + colorOne[2]))
            return "rgb(" + r +"," + g +"," + b +")"
      }

      getRandomGradientCSS() {
            let pos = Math.floor(Math.random()*2)
            return gradients[pos]
      }
}
