import React from 'react'
import { Helmet } from "react-helmet"
import ReactGA from 'react-ga'
import Header from './header'
import Footer from './footer'


import './layout.css'
import './layout.scss'
import * as theConst from '../utils/fileWithConstants';


export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    ReactGA.initialize(theConst.GA_CODE);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  componentDidUpdate() {
    console.log("Layout Updated:")
    ReactGA.pageview(window.location.pathname + window.location.search)
    console.log(window.location.pathname + window.location.search)
  }

  render() {
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui"/>
        </Helmet>
        <Header siteTitle="Creative Equity Partners" />
        <div className="contentLayout">
          {this.props.children}
        </div>
        <Footer siteTitle='Creative Equity Partners Inc.'/>
        </>
      )
    }
}