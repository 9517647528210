import React from 'react'
import "./markerButtonSolid.scss"
import { Spring,animated as a } from 'react-spring'


const MARKER = `M84,16.5c-9.3-9.3-20.6-14-33.7-14c-13.1,0-24.4,4.7-33.7,14c-9.3,9.3-14,20.6-14,33.7c0,6.8,1,12.3,3.1,16.7l34,72.2
c0.9,2,2.4,3.7,4.3,4.8c2,1.2,4.1,1.8,6.3,1.8s4.3-0.6,6.3-1.8s3.4-2.8,4.4-4.8l33.9-72.2c2-4.4,3.1-9.9,3.1-16.7
C98,37.1,93.3,25.8,84,16.5z M67.1,67.1c-4.7,4.7-10.3,7-16.9,7c-6.6,0-12.2-2.3-16.9-7c-4.7-4.7-7-10.3-7-16.9s2.3-12.2,7-16.9
c4.7-4.7,10.3-7,16.9-7c6.6,0,12.2,2.3,16.9,7c4.7,4.7,7,10.3,7,16.9S71.8,62.4,67.1,67.1z`

export default class MarkerButton extends React.Component {
  render() {
    return (
      <div className={'markerButtonWrapper'}>
        <a.svg viewBox="0 0 100.5 148.2" className={'markerButton'}>
        <a.g>
          <Spring native
            //config={{ delay:50, tension:200, friction: 100}}
            from ={{
              strokeDashoffset: 1000, 
              opacity: 0
            }}
            to={{ 
              strokeDashoffset: 0, 
              opacity: 1
            }}>
            {circleStyle => 
	            <a.path className={'marker'} style={circleStyle} d={MARKER}/>
            }
          </Spring>
          </a.g>
        </a.svg>
      </div>
  )
}}