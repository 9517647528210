import React from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ColorUtils from '../utils/colorUtils'

import Plx from 'react-plx';
import { elastic as Menu } from 'react-burger-menu'

import './navBarMini.scss'

const NavBarMiniParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: '87.5vh',
    duration: '5vh',
    properties: [
      {
        startValue: 1,
        endValue: 1,
        property: 'opacity'
      }
    ]
  },
]

export default class NavbarMini extends React.Component {

  targetRef = React.createRef()
  targetElement = null

  constructor (props) {
    super(props)

    this.handleTouchEndFunction = this.handleTouchEnd.bind(this)    

    this.state = {
      menuOpen: false,
      burgerMenuClass: 'BurgerMenuClosed',
      burgerButtonClass: 'BurgerButtonWhite',  
    }

    this.colorUtils = new ColorUtils()

  }

  componentDidMount() {
    window.addEventListener('touchend', this.handleTouchEndFunction,{passive: true})
    this.targetElement = this.targetRef.current

  }

  componentWillUnmount() {
    window.removeEventListener('touchend', this.handleTouchEndFunction)
  }

  handleTouchEnd(event) {
    //this.closeMenu()
  }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
    if(state.isOpen) {
      this.setState({burgerMenuClass: 'BurgerMenuOpened'})
    } else {
      this.setState({burgerMenuClass: 'BurgerMenuClosed'})
    }
  }


  closeMenu () {
    this.setState({menuOpen: false})
    this.setState({burgerMenuClass: 'BurgerMenuClosed'})
  }

  toggleMenu () {
    this.setState({menuOpen: !this.state.menuOpen})
  }
  
  toggleBurgerColorBlack () {
    console.log('Burger Black!')
    this.setState({burgerButtonClass: 'BurgerButtonBlack'})
  }

  toggleBurgerColorWhite () {
    console.log('Burger White!')
    this.setState({burgerButtonClass: 'BurgerButtonWhite'})
  }

  render() {
      return (     
          <div className="NavBarMini">
          <Plx parallaxData={NavBarMiniParallaxData} className="NavBarMiniParallax">
            <div className="NavBarMiniLogo">
              <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} to="/">CEP</AniLink>
            </div>
            <div className="NavBarMiniMenu">
              <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="you" className="nbm-item" to="/" activeClassName="active">What we do</AniLink>
              <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="who-we-are" className="nbm-item" to="/who-we-are/" activeClassName="active">Who we are</AniLink>
              <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="how-we-help" className="nbm-item" to="/services/" activeClassName="active">Services</AniLink>
              <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="services" className="nbm-item" to="/work/" activeClassName="active">Work</AniLink>
              {/*<AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="blog" className="nbm-item" to="/blog/1" activeClassName="active">Blog</AniLink>*/}
              <AnchorLink id="work" href="#contact" className="nbm-item">Get in touch</AnchorLink>
            </div>
            <div className={this.state.burgerMenuClass}>
            <Menu 
              right
              isOpen={this.state.menuOpen}
              onStateChange={(state) => this.handleStateChange(state)}
             >
              <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="you" className="nbm-item" to="/" onClick={() => this.closeMenu()} activeClassName="active">What we do</AniLink>
              <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="who-we-are" className="nbm-item" to="/who-we-are/" onClick={() => this.closeMenu()} activeClassName="active">Who we are</AniLink>
              <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="how-we-help" className="nbm-item" to="/services/" onClick={() => this.closeMenu()} activeClassName="active">Services</AniLink>
              <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="services" className="nbm-item" to="/work/" onClick={() => this.closeMenu()} activeClassName="active">Work</AniLink>
              {/*<AniLink cover bg={this.colorUtils.getRandomGradientCSS()} id="blog" className="nbm-item" to="/blog/1" onClick={() => this.closeMenu()} activeClassName="active">Blog</AniLink>*/}
              <AnchorLink id="work" href="#contact" className="nbm-item" onClick={() => this.closeMenu()} activeClassName="active">Get in touch</AnchorLink>
            </Menu>
            </div>

          </Plx>
        </div>       
    )
  }
}