import React from 'react'
import Link from 'gatsby-link';
import Plx from 'react-plx';
//import NavBar from './navBar'
import NavBar from './navBarV2'
import NavBarMini from './navBarMini'
//import { Spring, animated as a } from 'react-spring'

const LOGO_DURATION = '20vh'
const LOGO_OFFSET = '65vh'
const LOGO_OFFSET_ONE = '40vh'

const LogoParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: '100vh',
    duration: '1vh',
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity'
      }
    ]
  }
]

const LogoCreativeParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity'
      }
    ]
  },
]

const LogoEquityWrapperParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: LOGO_OFFSET_ONE,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 0,
        endValue: 1.39,
        unit: 'rem',
        property: 'translateX'
      }
    ]
  }
]

const LogoEquityParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 0,
        endValue: -1.94,
        unit: 'rem',
        property: 'translateY'
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity'
      }
    ]
  }
]

const LogoEquityFirstParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 0,
        endValue: -1.94,
        unit: 'rem',
        property: 'translateY'
      }
    ]
  }
]

const LogoPartnersWrapperParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: LOGO_OFFSET_ONE,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 0,
        endValue: 2.578,
        unit: 'rem',
        property: 'translateX'
      }
    ]
  }
]

const LogoPartnersParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 0,
        endValue: -3.88,
        unit: 'rem',
        property: 'translateY'
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity'
      }
    ]
  }
]

const LogoPartnersFirstParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 0,
        endValue: -3.88,
        unit: 'rem',
        property: 'translateY'
      }
    ]
  }
]
export default class Header extends React.Component {

  render(){
    return(
      <>
      <Link to="/">
        <div className="LogoWrapper">
        <Plx parallaxData={LogoParallaxData} className="LogoWrapperParallax">              
        <div className="LogoCreative">
          <span>C</span>
          <Plx parallaxData={LogoCreativeParallaxData}>        
            <span>reative</span>
          </Plx>
        </div>
      
        <Plx parallaxData={LogoEquityWrapperParallaxData}>              
        <div className="LogoEquity">
          <Plx parallaxData={LogoEquityFirstParallaxData}>              
            <span>E</span>
          </Plx>
          <Plx parallaxData={LogoEquityParallaxData}>              
            <span>quity</span>
          </Plx>
        </div>
        </Plx>
      
        <Plx parallaxData={LogoPartnersWrapperParallaxData}>              
        <div className="LogoPartners">
          <Plx parallaxData={LogoPartnersFirstParallaxData}>              
            <span>P</span>
          </Plx>
          <Plx parallaxData={LogoPartnersParallaxData}>              
            <span>artners</span>
          </Plx>
        </div>
        </Plx>
        </Plx>
      </div>
    </Link>
    
    
    <NavBar />
    <NavBarMini />  
    </>  
    )
  }
}
