import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import ColorUtils from '../utils/colorUtils'


import { Spring } from 'react-spring'
import Plx from 'react-plx';
import './navBarV2.scss'


const positionClose = 'translate3d(-150%,0,0)'
const positionOpen = 'translate3d(0,0,0)'
const NavBarParallaxData = [
  {
    start: '.SecondBlock',
    startOffset: '60vh',
    duration: '5vh',
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity'
      },
      {
        startValue: 0,
        endValue: -10,
        unit: 'vw',
        property: 'translateX'
      }
    ]
  },
]

class NavbarTwo extends React.Component {
  
  constructor (props) {
    super(props)
    this.state = {
      navBarClass: 'NavBarTwo'
    }
    this.colorUtils = new ColorUtils()
  }
  
  hideMenu () {
    this.setState({navBarClass: 'NavBarTwo Hidden'})
  }

  showMenu () {
    this.setState({navBarClass: 'NavBarTwo'})
  }
  
  render() {
      return (     
        <div className={this.state.navBarClass}>
        <Plx parallaxData={NavBarParallaxData} className="NavBarTwoParallax">              
          <Spring
          config={{ delay:0 }}
          from={{ transform: positionClose }}
          to={{ transform: positionOpen }}>
          {props => 
            <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} style={props} id="you" className="bm-item menu-item" to="/" activeClassName="active">What we do</AniLink>
          }
        </Spring>        

        <Spring
          config={{ delay:100 }}
          from={{ transform: positionClose }}
          to={{ transform: positionOpen }}>
          {props => 
            <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} style={props} id="who-we-are" className="bm-item menu-item" to="/who-we-are/" activeClassName="active">Who we are</AniLink>
          }
        </Spring>        

        <Spring
          config={{ delay:200 }}
          from={{ transform: positionClose }}
          to={{ transform: positionOpen }}>
          {props => 
            <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} style={props} id="how-we-help" className="bm-item menu-item" to="/services/" activeClassName="active">Services</AniLink>
          }
        </Spring>        
        

        <Spring
          config={{ delay:300 }}
          from={{ transform: positionClose }}
          to={{ transform: positionOpen }}>
          {props => 
            <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} style={props} id="services" className="bm-item menu-item" to="/work/" activeClassName="active">Work</AniLink>
            }
        </Spring>        
        {/*
        <Spring
          config={{ delay:400 }}
          from={{ transform: positionClose }}
          to={{ transform: positionOpen }}>
          {props => 
            <AniLink cover bg={this.colorUtils.getRandomGradientCSS()} style={props} id="blog" className="bm-item menu-item" to="/blog/1" activeClassName="active">Blog</AniLink>
            }
        </Spring>  
        */}
        <Spring
          config={{ delay:500 }}
          from={{ transform: positionClose }}
          to={{ transform: positionOpen }}>
          {props =>
            <AnchorLink style={props} className="bm-item menu-item" href="#contact" activeClassName="active">Get in touch</AnchorLink>
          }
        </Spring>        

        </Plx>
        </div>       
    )
  }
};

export default NavbarTwo;