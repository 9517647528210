import PropTypes from 'prop-types'
import Link from 'gatsby-link';
import React from 'react'
import Plx from 'react-plx';

import "./footer.scss"
import MarkerButtonSolid from '../components/markerButtonSolid'

const COLUMN_DURATION = '30vh'

const LOGO_DURATION = '20vh'
const LOGO_OFFSET = '0vh'
const LOGO_OFFSET_ONE = '0vh'

const LogoCreativeParallaxData = [
  {
    start: '.LogoColumn',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity'
      }
    ]
  },
]

const LogoEquityWrapperParallaxData = [
  {
    start: '.LogoColumn',
    startOffset: LOGO_OFFSET_ONE,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 1.475,
        endValue: 0,
        unit: 'rem',
        property: 'translateX'
      }
    ]
  }
]

const LogoEquityParallaxData = [
  {
    start: '.LogoColumn',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: -1.925,
        endValue: 0,
        unit: 'rem',
        property: 'translateY'
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity'
      }
    ]
  }
]

const LogoEquityFirstParallaxData = [
  {
    start: '.LogoColumn',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: -1.925,
        endValue: 0,
        unit: 'rem',
        property: 'translateY'
      }
    ]
  }
]

const LogoPartnersWrapperParallaxData = [
  {
    start: '.LogoColumn',
    startOffset: LOGO_OFFSET_ONE,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: 2.75,
        endValue: 0,
        unit: 'rem',
        property: 'translateX'
      }
    ]
  }
]

const LogoPartnersParallaxData = [
  {
    start: '.LogoColumn',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: -3.9,
        endValue: 0,
        unit: 'rem',
        property: 'translateY'
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity'
      }
    ]
  }
]

const LogoPartnersFirstParallaxData = [
  {
    start: '.LogoColumn',
    startOffset: LOGO_OFFSET,
    duration: LOGO_DURATION,
    properties: [
      {
        startValue: -3.9,
        endValue: 0,
        unit: 'rem',
        property: 'translateY'
      }
    ]
  }
]

const MarkerParallaxData = [
  {
    start: 'self',
    duration: COLUMN_DURATION,
    properties: [
      {
        startValue: 90,
        endValue: 0,
        unit: 'deg',
        property: 'rotateY'
      }
    ]
  }
]

const ColumnBorderParallaxData = [
  {
    start: 'self',
    duration: COLUMN_DURATION,
    properties: [
      {
        startValue: 0,
        endValue: 100,
        unit: '%',
        property: 'height'
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity'
      }
    ]
  }
]

const LogoColumnParallaxData = [
  {
    start: 'footer',
    duration: '10vh',
    properties: [
      {
        startValue: -20,
        endValue: 0,
        unit: 'vh',
        property: 'translateY'
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity'
      }
    ]
  }
]

const ColumnParallaxData = [
  {
    start: 'self',
    duration: '25vh',
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity'
      }
    ]
  }
]
const Footer = ({ siteTitle }) => (
    <footer>
      <div className="LogoColumn">
        <Plx parallaxData={ LogoColumnParallaxData }> 
        <Link to="/">
        <div className="LogoWrapper">
        <div className="LogoCreative">
          <span>C</span>
          <Plx parallaxData={LogoCreativeParallaxData}>        
            <span>reative</span>
          </Plx>
        </div>
      
        <Plx parallaxData={LogoEquityWrapperParallaxData}>              
        <div className="LogoEquity">
          <Plx parallaxData={LogoEquityFirstParallaxData}>              
            <span>E</span>
          </Plx>
          <Plx parallaxData={LogoEquityParallaxData}>              
            <span>quity</span>
          </Plx>
        </div>
        </Plx>
      
        <Plx parallaxData={LogoPartnersWrapperParallaxData}>              
        <div className="LogoPartners">
          <Plx parallaxData={LogoPartnersFirstParallaxData}>              
            <span>P</span>
          </Plx>
          <Plx parallaxData={LogoPartnersParallaxData}>              
            <span>artners</span>
          </Plx>
        </div>
        </Plx>
      </div>
    </Link>
    </Plx>
  </div>  
      
      <div className="PeopleColumn">
        <Plx parallaxData={ ColumnBorderParallaxData } className="BorderLeftWrapper">
          <div className="BorderLeft"></div>
        </Plx>
        <Plx parallaxData={ ColumnParallaxData }  className="FooterContentBlockWrapper">
        <div className="FooterContentBlock">
          <h3>OREN KATZ</h3>
          <h4 className="italic">Partner, Design</h4>
          <a href="mailto:oren@creativeequitypartners.com"><h4>oren@creativeequitypartners.com</h4></a>
        </div>
        <div className="FooterContentBlock">
          <h3>ADRIENNE KATZ</h3>
          <h4 className="italic">Partner, Strategy</h4>
          <a href="mailto:adrienne@creativeequitypartners.com"><h4>adrienne@creativeequitypartners.com</h4></a>
        </div>
        </Plx>
      </div>

      <div className="ContactColumn">
        <Plx parallaxData={ ColumnBorderParallaxData } className="BorderLeftWrapper">
          <div className="BorderLeft"></div>
        </Plx>
        <Plx parallaxData={ ColumnParallaxData } className="FooterContentBlockWrapper">
        <div className="FooterContentBlock">
          <h3>BECOME A CLIENT</h3>
          <a href="mailto:change@creativeequitypartners.com"><h4>change@creativeequitypartners.com</h4></a>
          <a href="tel:/416.915.1237"><h4>416.915.1237</h4></a>
        </div>
        
        <div className="FooterContentBlock">
          <h3>JOIN CEP</h3>
          <a href="mailto:join@creativeequitypartners.com"><h4>join@creativeequitypartners.com</h4></a>
        </div>
        </Plx>


      </div>

      <div className="AddressColumn">
        <Plx parallaxData={ ColumnBorderParallaxData } className="BorderLeftWrapper">
          <div className="BorderLeft"></div>
        </Plx>
        <Plx parallaxData={ ColumnParallaxData } className="FooterContentBlockWrapper">
        
          <Plx parallaxData={ MarkerParallaxData } className="MarkerWrapper">          
            <MarkerButtonSolid/>
          </Plx>
        <h4>
        Designers Walk District<br/>
        Toronto, ON
        </h4>
        <h4 className="Bottom">&copy; {new Date().getFullYear()} {siteTitle}</h4>
        </Plx>

      </div>
      <div id="contact"></div>
    </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer